.answer-box {
    padding: 30px;
}

.question-count {
    text-align: center;
    margin-bottom: 30px;
}

.question {
    text-align: center;
    margin-bottom: 30px !important;
}

.answer {
    cursor: pointer;
}

.answer:hover,
.answer.selected {
    background-color: rgb(25, 118, 210);
    color: white;
}

.control {
    margin-top: 30px;
    text-align: center;
}

.control button {
    width: 100%;
}
