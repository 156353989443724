.form {
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.control {
    margin-top: 30px;
    text-align: center;
    width: 100%;
}

.control button {
    width: 100%;
}
