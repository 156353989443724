.navbar {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.logo {
    height: 5vmin;
    cursor: pointer;
}

.title {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
}

.theme-toggler {
    margin-left: auto;
}
