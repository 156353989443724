.result-box {
    padding: 30px;
}

.result-title {
    text-align: center;
    margin-bottom: 30px;
}

.control {
    margin-top: 30px;
    text-align: center;
}
